import striptags from "striptags";
import {date2Iso, date2Mysql, iso2Date, iso2DDMMYYYY, iso2HHMM, mysql2Date} from "@/utils/date";
import Config from "@/config";
import {ACTIONS} from "@/utils/msg";
import store from "@/store";
import router from "@/router";
import {findPs} from "@/utils/p";
import {end, md5} from "@/utils/string";

export const STATUSES = {
    DONE: "done",
    PRIO: "prio",
    DELAYED: "delayed",
    DEADLINE: "deadline",
    TEMPLATE: "template",
    REPEATED: "repeated",
};

export const TABS = {
    ACTUAL: "actual",
    DELAYED: "delayed",
    DONE: "done",
    TEMPLATES: "templates",
};

export const colors = {
    TASK_TEMPLATE: "purple lighten-1",
    TASK_TEMPLATE_STOPPED: "purple lighten-3",
    TASK_DEADLINE: "warning",
    TASK_PRIO: "red",
    TASK_DONE: "success",
};

export const statuses = [
    /*{
        id: STATUSES.DONE,
        icon: "mdi-check",
        type: "success",
        appliesTo: item => item.isDone || item.doneAt,
        text: () => "Задача выполнена",
    },*/
    /*{
        id: STATUSES.PRIO,
        icon: "mdi-fire",
        type: "error",
        appliesTo: item => item.prio,
        text: () => "Важная задача!",
        onClick: item => {
            store.dispatch("task/update", {
                taskAuth: item.auth,
                params: {
                    "prio": !item.prio
                }
            });
        }
    },*/
    /*{
        id: STATUSES.DELAYED,
        icon: "mdi-timer-sand-empty",
        type: "info",
        appliesTo: item => item.delayedTill || item.isDelayed,
        text: (item) => {
            let text = "Задача отложена";
            if (item.delayedTill) {
                let hm = iso2HHMM(item.delayedTill);
                if (hm === "00:00") hm = "";
                else hm = " " + hm;
                text += " до " + iso2DDMMYYYY(item.delayedTill) + hm;
            }// else text += " бессрочно";
            return text;
        },
        onClick: item => {
            store.dispatch("task/dialogDelay", {
                item,
            });
        }
    },*/
    {
        id: STATUSES.TEMPLATE,
        icon: "mdi-view-grid-plus-outline",
        color: item => (item.isDone || item.doneAt) ? colors.TASK_TEMPLATE_STOPPED : (item.repeat ? colors.TASK_TEMPLATE : colors.TASK_TEMPLATE_STOPPED),
        dark: true,
        //msg: "поставил отметку 'Важно!'",
        appliesTo: item => item.isTemplate,
        text(item) {
            if (item.isDone || item.doneAt) return "Повторения приостановлены";

            let repeatDays = item.repeatDays;
            //console.log("type of repeatDays", this.item.auth, this.item.repeatDays, typeof repeatDays === "string");
            if (typeof repeatDays === "string") {
                try {
                    repeatDays = JSON.parse(repeatDays);
                } catch (e) {
                }
            }

            let repeatText = "";
            if (item.repeat === "hourly") {
                repeatText = "ежечасно";
            } else if (item.repeat === "daily") {
                repeatText = "ежедневно";
            } else if (item.repeat === "weekly" && repeatDays) {
                repeatText = "по " + Config.DAY_LABELS.filter((el, index) => repeatDays.includes(index)).join(", ");
            } else if (item.repeat === "monthly" && repeatDays) {
                repeatText = "в определенные дни месяца";//: " + repeatDays.map(el => el + 1).join(", ");
            } else if (item.repeat === "yearly" && repeatDays) {
                repeatText = "в определенные дни года";//: " + repeatDays.map(el => iso2DDMMYYYY(el)).join(", ");
            } else return "Режим повторений не установлен";
            return "Задача повторяется " + repeatText;

        },
        onClick: item => {
            store.dispatch("task/dialogTemplate", {
                item,
            });
        }
    },
    {
        id: STATUSES.DEADLINE,
        icon: "mdi-clock-time-eight-outline",
        color: (item) => deadlineTextAndCss(item).color,
        dark: true,
        light: (item) => {
            if (item.isTemplate) return true;
            const date = iso2Date(item.deadline);
            date.setDate(date.getDate() + 1);
            const now = new Date();
            return date > now;
        },
        appliesTo: item => !(item.doneAt || item.isDone) && (item.deadline || (item.isTemplate && item.deadlineDays)),
        text: item => {
            if (item.isTemplate) return "Дедлайн устанавливается в " + deadlineTextAndCss(item).text;
            else {
                const date = iso2Date(item.deadline);
                date.setDate(date.getDate() + 1);
                const now = new Date();
                let overdue = date < now ? "просрочен " : "";
                return "Дедлайн " + overdue + deadlineTextAndCss(item).text;
            }
        },
        onClick: item => {
            store.dispatch("task/dialogDeadline", {
                item,
            });
        }
    },
    {
        id: STATUSES.REPEATED,
        icon: "mdi-view-grid-plus-outline",
        color: "info",
        dark: true,
        //msg: "поставил отметку 'Важно!'",
        appliesTo: item => item.templateId,
        text(item) {
            if (!item.templateId) return null;

            const template = store.state.page.task.template;
            if (template) {
                return "Задача создана из шаблона " + clearName(template.name);
            } else return "Задача создана из шаблона ";//+clearName(template.name);
        },
        onClick: item => {
            const template = store.state.page.task.template;

            // todo quick solution
            const path = router.currentRoute.path;
            //console.log("PATH", path);
            const newPath = path.replace(/(.+\/).+?$/, "$1" + template.auth);

            router.push(newPath);
        }
    },
];

export const prios = [
    {
        label: "Срочная",
        icon: "mdi-fire",
        color: "red",
        prio: 1,
    },
    {
        label: "Важная",
        icon: "mdi-flag",
        color: "red",
        prio: 2,
    },
    {
        label: "Перспективная",
        icon: "mdi-leaf",
        color: "green",
        prio: 3,
    },
    {
        label: "Бэклог",
        icon: "mdi-lightbulb-outline",
        color: "blue",
        prio: -1,
    },
    {
        label: "Без приоритета",
        //icon: "mdi-radiobox-indeterminate-variant",
        icon: "mdi-flag-outline",
        color: "grey lighten-2",
        prio: 0,
    },
];

export const deadlines = [
    {
        label: "Наступил",
        icon: "mdi-fire",
        color: "error",
        value: "passed",
    },
    {
        label: "Сегодня",
        icon: "mdi-fire",
        color: "warning",
        value: 0,
    },
    {
        label: "Сегодня или завтра",
        icon: "mdi-fire",
        color: "warning",
        value: 1,
    },
    {
        label: "В ближайшие 2 дня",
        icon: "mdi-fire",
        color: "warning",
        value: 2,
    },
    {
        label: "На этой неделе",
        icon: "mdi-fire",
        color: "warning",
        value: "week",
    },
    {
        label: "В ближайшие 2 недели",
        icon: "mdi-fire",
        color: "warning",
        value: "2-weeks",
    },
];

export const delays = [
    {
        label: "На завтра",
        value: 1,
    },
    {
        label: "На ближайшие 2 дня",
        value: 2,
    },
    {
        label: "На эту неделю",
        value: "week",
    },
    {
        label: "На ближайшие 2 недели",
        value: "2-weeks",
    },
];

/**
 * Возвращает первый параграф из текста описания задачи, очищенный от тэгов.
 * @param name
 * @returns {string|*}
 */
export const clearName = (name, keepTags = []) => {
    if (!name) return "";

    const ps = findPs(name);
    //console.log("PS", ps);
    if (ps?.length) name = ps[0];

    // remove html tags
    name = striptags(name, keepTags);

    // remove hashtags
    name = name.replace(/(^|\s|>)#[^<\s$]+/ig, "$1").trim();

    // remove html entities
    name = name.replace(/\&nbsp\;/ig, " ").trim();

    //if (name.length > 128) name = name.substr(0, 128) + "...";

    return name.trim();
}

export const clearText = (name) => {
    if (!name) return "";

    // remove html tags
    name = striptags(name);

    // remove hashtags
    name = name.replace(/(^|\s|>)#[^<\s$]+/ig, "$1").trim();

    // remove html entities
    name = name.replace(/\&nbsp\;/ig, " ").trim();

    //if (name.length > 128) name = name.substr(0, 128) + "...";

    return name.trim();
}

export const parentsOf = (items, item) => {
    const parents = [];
    const findParentOf = (item) => {
        const parent = items.find(el => el.id === item.todoId);
        if (parent) {
            //console.log("parent of " + item.name + ": " + parent.name);
            findParentOf(parent);
            parents.push(parent);
        }
        //else console.log("no parent of " + item.name);
    }
    findParentOf(item);
    return parents.length ? parents : null;
}

export const childrenOf = (items, item) => {
    const children = [];
    const findChildrenOf = (item) => {
        const child = items.find(el => el.todoId === item.id);
        if (child) {
            //console.log("parent of " + item.name + ": " + parent.name);
            children.push(child);
            findChildrenOf(child);
        }
        //else console.log("no parent of " + item.name);
    }
    findChildrenOf(item);
    return children.length ? children : null;
}

export const actualItemsFrom = (items, todoSort, tabName) => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const now = new Date().valueOf();
    return sortedItems(items

        // filter out children of undone parents
        .filter(el => {
            if (el.todoId) {
                const parents = parentsOf(items, el);
                //if (el.auth === "KvTc88lSXYM0VsRN") console.log("parents", parents);
                // has all parents done?
                return !parents || parents?.reduce((all, item) => all && !!item.doneAt, true);
            }
            return true;
        })

        .filter(el => !el.isTemplate && !el.isPersonal)
        .filter(el => !el.delayedTill && !el.isDelayed)
        /*.filter(el => {
            // ignore minuses if there are active non-minus tags
            if (this.activeTags.find(el => !el.isMinus)) return true;
            // remove inactive minus-tagged, but not with prio
            if (el.prio) return true;
            return !this.tagsOf(el).some(text => {
                const tag = this.tags.find(tag => tag.name === text);
                return tag?.isMinus && !tag.active;
            });
        })*/
        .filter(el => {
            // add done today items
            if (!el.doneAt) return true;
            if (el.isDone) return true;
            const d = iso2Date(el.doneAt).valueOf();
            const days = Math.floor((now - d) / millisecondsPerDay);
            return days <= 0;
        }), todoSort, tabName)

        // filter out done parents of done items
        .filter(el => {
            if (el.doneAt || el.isDone) {
                //console.log("TASK "+el.name+" DONE");
                const doneChild = items.find(child => child.todoId === el.id);
                //console.log("\thas done child", doneChild);
                return !doneChild || !doneChild.doneAt;
            }
            return true;
        });
};

/**
 * Should be same as on backend.
 * @deprecated
 * @param items
 * @param todoSort
 * @param tabName
 * @param force
 * @returns {*}
 */
export const sortedItems = (items, todoSort, tabName, userId = null, force = false) => {
    const indexes = {};
    const indexOfId = (id) => {
        let index = -1;
        if (indexes['id' + id]) index = indexes['id' + id];
        else if (Array.isArray(todoSort[tabName])) index = indexes['id' + id] = todoSort[tabName].findIndex(el => el === id);
        else if (Array.isArray(todoSort)) index = indexes['id' + id] = todoSort.findIndex(el => el === id);
        return index;
    }
    return items?.sort((a, b) => {
        //флаги, баги, текущие, тесты, выполненные сегодня

        //if ( a.sort !== null && b != null) return a - b;

        if (force) {
            if (userId) {
                if (a.userId === userId && b.userId !== userId) return -1;
                if (a.userId !== userId && b.userId === userId) return 1;
            }

            if (!a.doneAt && b.doneAt) return -1;
            if (a.doneAt && !b.doneAt) return 1;

            if (!a.delayedTill && b.delayedTill) return -1;
            if (a.delayedTill && !b.delayedTill) return 1;

            if (!a.isDelayed && b.isDelayed) return -1;
            if (a.isDelayed && !b.isDelayed) return 1;

            if (a.prio && !b.prio) return -1;
            if (!a.prio && b.prio) return 1;
        }

        // take group sorting
        //tabIndex = tabIndex || 0;
        //const tabName = TAB_NAMES[tabIndex];
        //console.log("todoSort", todoSort, tabName);

        if (todoSort) {
            const aIndex = indexOfId(a.id);
            const bIndex = indexOfId(b.id);
            //if (aIndex < 0 && bIndex >= 0) return -1;
            //if (aIndex >= 0 && bIndex < 0) return 1;
            return aIndex - bIndex;
        }

        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;

        /*if (a.createdAt && !b.createdAt) return -1;
        if (!a.createdAt && b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;*/

        return 0;
    });
};

/**
 * Should be same as on backend.
 * @param items
 * @param userId
 * @param isDesc
 * @returns {boolean}
 */
export const sortItems = (items, userId = null, isDesc = false) => {
    if (!Array.isArray(items)) return false;
    if (!items.length) return false;

    const mapBefore = md5(items.map(el => el.auth));
    //console.log("MAP BEFORE", items.map(el=>el.auth));

    const currentSorting = items.map(el => el.id);
    const indexes = {};
    const indexOfId = (id) => {
        if (typeof indexes['id' + id] != "undefined") return indexes['id' + id];
        indexes['id' + id] = currentSorting.findIndex(el => el === id);
        return indexes['id' + id];
    }

    const nowIso = date2Mysql(new Date());
    items.sort((a, b) => {

        if (a.isActive && !b.isActive) return -1;
        if (b.isActive && !a.isActive) return 1;

        if (a.deadline && a.deadline < nowIso && (!b.deadline || b.deadline > a.deadline)) return -1;
        if (b.deadline && b.deadline < nowIso && (!a.deadline || a.deadline > b.deadline)) return 1;

        /*if ( a.isActive && b.isActive ) {
            if (a.deadline && a.deadline < nowIso && (!b.deadline || b.deadline > nowIso)) return -1;
            if (b.deadline && b.deadline < nowIso && (!a.deadline || a.deadline > nowIso)) return 1;
            if (a.deadline && b.deadline && a.deadline < nowIso && b.deadline < nowIso && a.deadline < b.deadline) return -1;
            if (a.deadline && b.deadline && a.deadline < nowIso && b.deadline < nowIso && a.deadline > b.deadline) return -1;
        }*/

        //if (userId && !a.deadline && !b.deadline) {
        if (userId) {
            if (a.userId === userId && b.userId !== userId) return -1;
            if (a.userId !== userId && b.userId === userId) return 1;
        }

        /*if (!a.doneAt && b.doneAt) return -1;
        if (a.doneAt && !b.doneAt) return 1;
        if (!a.isDone && b.isDone) return -1;
        if (a.isDone && !b.isDone) return 1;

        if (!a.delayedTill && b.delayedTill) return -1;
        if (a.delayedTill && !b.delayedTill) return 1;

        if (!a.isDelayed && b.isDelayed) return -1;
        if (a.isDelayed && !b.isDelayed) return 1;*/

        const prioA = Number(a.prio);
        const prioB = Number(b.prio);
        if (prioA > 0 && prioB > 0) return prioA - prioB;
        if (prioA > 0 && prioB <= 0) return -1;
        if (prioA <= 0 && prioB > 0) return 1;
        if (prioA === 0 && prioB < 0) return -1;
        if (prioA < 0 && prioB === 0) return 1;

        // sort by current position
        const aIndex = indexOfId(a.id);
        const bIndex = indexOfId(b.id);
        return aIndex - bIndex;

        // this sorting should now gone
        //if (a.updatedAt > b.updatedAt) return -1;
        //if (a.updatedAt < b.updatedAt) return 1;

        /*if (a.createdAt && !b.createdAt) return -1;
        if (!a.createdAt && b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;*/

        return 0;
    });
    if (isDesc) items.reverse();

    //console.log("MAP AFTER", items.map(el=>el.auth));
    const mapAfter = md5(items.map(el => el.auth));
    return mapAfter !== mapBefore;
};

export const prioFromText = (orignalText, originalPrio) => {
    const text = clearText(orignalText);
    if (!text) return;

    let prio = null;

    const title = clearName(orignalText);
    //console.log("TITLE", title);
    if (title.match(/!!!/)) prio = 1;
    else if (title.match(/!!1/)) prio = 1;
    else if (title.match(/!!2/)) prio = 2;
    else if (title.match(/!!3/)) prio = 3;
    else if (title.match(/!!4/)) prio = 0;
    else if (title.match(/!!5/)) prio = -1;
    else if (title.match(/!!/)) prio = 1;   //2
    /*else {
        //if ( originalPrio > 0 ) prio = 0;

        //console.log("TITLE PRIO NO FOUND");

        // check whole text against !![1-5]
        const prioMatches = text.matchAll(/!![1-5]/mg);
        [...prioMatches].forEach(m => {
            m = m[0];
            if (m.match(/!!1/)) prio = 1;
            else if (m.match(/!!2/) && (prio <= 0 || prio > 2)) prio = 2;
            else if (m.match(/!!3/) && prio <= 0) prio = 3;
            else if (m.match(/!!4/) && (prio < 0 || prio === null)) prio = 0;
            else if (m.match(/!!0/) && (prio < 0 || prio === null)) prio = 0;
            else if (m.match(/!!5/) && prio === null) prio = -1;
            console.log("MATCH: " + m + ":" + prio);
        });
    }*/

    //console.log("ORIGINAL PRIO: " + originalPrio);
    //console.log("PRIO FROM TEXT: " + prio);
    if (prio === null) prio = originalPrio;
    //console.log("FINAL PRIO: " + prio);
    return prio;
};

export const tagsFromText = (text) => {
    text = striptags(text, [], '\n');
    const matches = [...text.matchAll(/(\s|^)#([\wа-я-_]+)/igs)];
    return matches.map(el => el[2]).filter(el => !!el.trim().toLowerCase());
}

export const deadlineTextAndCss = (task) => {
    let text, css, color;
    if (task.isTemplate) {
        if (task.deadlineDays === 7) text = "+1 неделя";
        else if (task.deadlineDays === 14) text = "+2 недели";
        else text = "+" + task.deadlineDays
                + " " + end(task.deadlineDays, "день", "дня", "дней");
        return {
            text,
            color: "warning",
        };
    }

    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    const date = iso2Date(task.deadline);
    const diffTime = Math.floor(date / 1000) - Math.floor(now / 1000);
    //console.log("DATE", date, now);
    const diffDays = Math.floor(diffTime / (60 * 60 * 24));
    //console.log("diffTime", diffTime);
    //console.log("diffDays", diffDays);
    if (task.isTemplate) return "+"
        + task.deadlineDays
        + " " + end(task.deadline, "день", "дня", "дней");
    else if (diffDays === 0) {
        text = "сегодня";
        css = "warning--text";
        color = "warning";
    } else if (diffDays === 1) {
        text = "завтра";
        css = "warning--text";
        color = "warning";
    } else if (diffDays === 2) {
        text = "послезавтра";
        css = "warning--text";
        color = "warning";
    } else if (diffDays >= 3 && diffDays < 7) {
        text = "через " + diffDays + " " + end(diffDays, "день", "дня", "дней");
    } else if (diffDays === 7) {
        text = "через неделю";
    } else if (diffDays > 7) {
        text = iso2DDMMYYYY(task.deadline);
    } else if (diffDays === -1) {
        text = "вчера";
        css = "error--text";
        color = "error";
    } else if (diffDays === -2) {
        text = "позавчера";
        css = "error--text";
        color = "error";
    } else if (diffDays <= -3 && diffDays > -7) {
        text = (-diffDays) + " " + end(-diffDays, "день", "дня", "дней") + " назад";
        css = "error--text";
        color = "error";
    } else if (diffDays === -7) {
        text = "неделю назад";
        css = "error--text";
        color = "error";
    } else if (diffDays < -7) {
        text = iso2DDMMYYYY(task.deadline);
        css = "error--text";
        color = "error";
    }

    return {
        text,
        css,
        color
    }
};

export const isTaskOverdue = (item) => {
    if (!item) return false;
    if (!item.deadline) return false;
    //const date = iso2Date(item.deadline);
    //date.setDate(date.getDate() + 1);
    const now = new Date();
    return item.deadline < date2Mysql(now);
};
export const isTaskOfMyAttention= (item) => {
    if (!item) return false;
    const user = store.state.session.user;
    return item.userId === user.id
        || (!isTaskDone(item)
            && isTaskOverdue(item)
            && (item.ownerId === user.id
                || (!item.ownerId && item.creatorId === user.id))
        );
};
export const isTaskDone = (item) => {
    if (!item) return false;
    return !!(item.isDone || item.doneAt);
};
export const isOneTaskDone = (item) => {
    if (!item) return false;
    if (store.state.session.isAnon) return false;
    return isTaskInactive(item)
        || !isTaskOfMyAttention(item);
};
export const isTaskDelayed = (item) => {
    if (!item) return false;
    return !!(item.isDelayed || !!item.delayedTill);
};
export const isTaskInactive = (item) => {
    return isTaskDone(item) || isTaskDelayed(item);
};
export const isTaskActive = (item) => {
    return !isTaskInactive(item);
};
export const count1TaskDoneTasks = () =>  {
    const tasks = store.state.page.tasks.items;
    return tasks?.filter(el => isOneTaskDone(el))?.length;
};
export const count1TaskUndoneTasks = () => {
    const tasks = store.state.page.tasks.items;
    return tasks?.length - count1TaskDoneTasks;
};
export const countContextInactiveTasks = () => {
    const tasks = store.state.page.tasks.items;
    return tasks?.filter(el => isTaskInactive(el))?.length;
};
export const countContextActiveTasks = () => {
    const tasks = store.state.page.tasks.items;
    return tasks?.filter(el => isTaskActive(el))?.length;
};

export const contextHasDelayedTill = (context) => {
    if (!context ) return false;
    if (!context.isPick) return false;
    let conds = context.conds;
    if (!conds?.length) return false;

    return !!conds.find(fields => !!fields.find(field => field.key === "delayedTill"));
}

export const rootContextId = (context) => {
    if (!context ) return false;
    if (context.isPick) return false;
    if ( context.contextId?.length !== Config.AUTH_LENGTH * 2 ) return false;
    return context.contextId.substring(0, Config.AUTH_LENGTH);
}

export const pickSingleValue = (pick, prop) => {
    if (!pick ) return null;
    if (!pick.isPick) return null;
    //console.log("pickSingleValue", pick);
    if (!pick.conds) return null;
    if (pick.conds.length>1) return null;
    if (pick.conds[0].length > 1) return null;
    if ( pick.conds[0][0].key === prop ) return pick.conds[0][0].value;
    return null;
}
