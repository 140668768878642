import {obtainClientAuth} from "../utils/auth";

export default {
    isInited: false,
    isAnon: false,

    user: null,
    workspace: null,

    /**
     * Current environment: workspaces / lists / contexts / groups / topics / users
     */
    area: {
        workspaces: null,
        picks: null,
        groups: null,
        topics: null,
        users: null,
        onlineUsers: null,
    },
}