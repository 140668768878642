import Config from '@/config';
import Vue from "vue";
import {end} from "@/utils/string";

const actions = {
    confirm(store, params) {
        return new Promise((resolve, reject)=>{
            store.rootState.ui.dialogConfirm.title = params?.title;
            store.rootState.ui.dialogConfirm.message = params?.message;
            store.rootState.ui.dialogConfirm.ok = params?.ok;
            store.rootState.ui.dialogConfirm.callback = (res)=>{
                return resolve(res);
            };
            store.rootState.ui.dialogConfirm.isVisible = true;
        });
    },
    dialogSelect(store, params) {
        if (!params?.item) return;

        return new Promise((resolve, reject) => {
            console.log("dialogSelect", params);
            store.rootState.ui.dialogSelect.item = params.item;
            store.rootState.ui.dialogSelect.prop = params.prop;
            store.rootState.ui.dialogSelect.title = params.title || null;
            store.rootState.ui.dialogSelect.label = params.label || null;
            store.rootState.ui.dialogSelect.text =  params.text || null;
            store.rootState.ui.dialogSelect.popularOptions = params.popularOptions || null;
            store.rootState.ui.dialogSelect.options = params.options || null;
            store.rootState.ui.dialogSelect.callback = res => resolve(res);
            store.rootState.ui.dialogSelect.isVisible = true;
        });
    },
    dialogContextSelector(store, params) {
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiMenu', {
                menu: store.rootState.ui.dialogContextSelector,
                params
            }, {root: true});
        });
    },
    dialogPick(store, params) {
        return new Promise((resolve, reject) => {
            console.log("dialogPick", params);
            store.rootState.ui.dialogPick.item = params?.item;
            store.rootState.ui.dialogPick.callback = (res) => resolve(res);
            store.rootState.ui.dialogPick.isVisible = true;
        });
    },
    valueSelector(store, params) {
        console.log("valueSelector");
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiMenu', {
                menu: store.rootState.ui.valueSelector,
                params
            }, {root: true});
        });
    },
    tagSelector(store, params) {
        console.log("tagSelector");
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiMenu', {
                menu: store.rootState.ui.tagSelector,
                params
            }, {root: true});
        });
    },
    prioSelector(store, params) {
        console.log("prioSelector");
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiMenu', {
                menu: store.rootState.ui.prioSelector,
                params
            }, {root: true});
        });
    },
    contextMenu(store, params) {
        console.log("contextMenu");
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiMenu', {
                menu: store.rootState.ui.contextMenu,
                params
            }, {root: true});
        });
    },
};

export default actions;
