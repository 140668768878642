import {initPicksInArea} from "@/utils/area";

const mutations = {
    pickCreate: (state, payload) => {
        console.log("MUTATION pick/create", payload);

        // after creation - call this
        payload.mutation = "pickAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: payload.contextId,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    pickFrom: (state, payload) => {
        console.log("MUTATION pick/from", payload);

        // after creation - call this
        payload.mutation = "pickAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: payload.contextId,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    pickAppend: (state, payload) => {
        console.log("MUTATION pick/append", payload);

        const item = payload?.pickAppend?.item;
        if (!item) return;

        const area = state.session.area;
        let items = area.picks;

        // append item
        if (items?.findIndex(el => el.id === item.id) === -1) {
            items.push(item);
            initPicksInArea(area);
            console.log("APPENDED PICK", item);
        }

    },
};

export default mutations;
