import Vue from "vue";
import store from "@/store";
import Config from "@/config";
import {nick} from "@/utils/user";
import {date2Mysql, iso2Date, iso2DDMMYYYY, iso2HHMM, mysql2Date} from "@/utils/date";
import router from "@/router";
import {sortItems, colors, TABS, contextHasDelayedTill, rootContextId} from "@/utils/task";

/**
 * Base component.
 * Encapsulates some widely used computed variables and methods.
 */
export default {
    data() {
        return {
            COLOR_TASK_TEMPLATE: colors.TASK_TEMPLATE,
            COLOR_TASK_TEMPLATE_STOPPED: colors.TASK_TEMPLATE_STOPPED
        }
    },
    computed: {
        hasData() {
            return this.session.isInited && this.page.isInited && !this.is403 && !this.is404;
        },
        hasSessionData() {
            return this.session.isInited;
        },
        hasContextData() {
            return this.session.isInited && this.context.isInited;
        },

        // session --------------------

        session() {
            return this.$store.state.session;
        },
        user() {
            return this.session.user;
        },
        currentUserNick() {
            return nick(this.user);
        },
        currentUserName() {
            return this.user.firstName || this.user.name;
        },
        workspace() {
            return this.session.workspace;
        },
        onlineUsers() {
            return this.session.area?.onlineUsers?.filter(el => el.auth !== this.user?.auth);
        },
        /**
         * Current user has full access.
         * @returns {boolean}
         */
        isAuthorized() {
            //return this.$store.state.isAuthorized;
            return !this.isLimitedAccess;
        },
        /**
         * Current user has full access.
         * Alias for isAuthorized.
         * @returns {boolean}
         */
        isAuthed() {
            return this.isAuthorized;
        },
        isAnon() {
            return this.session.isAnon;
        },
        is403() {
            return this.session.isAnon && (
                !this.isRouteTask
            );
        },
        is404() {
            // todo
            return false;
        },

        // ui -------------------
        ui() {
            return this.$store.state.ui;
        },
        pending() {
            return this.ui.pending;
        },

        withGroupFullAccess() {
            return !this.pageGroup || this.pageGroup.isFullAccess;
        },
        withTaskFullAccess() {
            return this.task.isFullAccess;
        },
        isLimitedAccess() {
            return this.isAnon || (
                this.context.isInited && !this.context.isFullAccess//!this.group && !this.topic && !this.isRouteMy
            );
        },
        isFullAccess() {
            return !this.isLimitedAccess;
            /*return (this.withGroupFullAccess && (!this.isRouteTask || this.withTodoFullAccess))
                || (this.isRoute1Task && this.withTodoFullAccess);*/
        },
        search: {
            get() {
                return this.ui.search.value;
            },
            set(v) {
                this.$store.commit("uiSetSearch", v);//$store.state.ui.search.value = v;
            }
        },
        isSearching() {
            return this.ui.search.isSearching;
        },
        is1TaskMode() {
            return this.isRoute1Task
                || (this.isRouteContext && this.context.item?.is1Task)
                || (this.isRouteGroup && this.group?.is1Task)
                || (this.isRouteTopic && this.topic?.is1Task);
        },

        // area ---
        area() {
            return this.session.area;
        },
        workspaces() {
            return this.area?.workspaces;
        },
        picks() {
            return this.area?.picks;
        },
        contexts() {
            return this.area?.contexts;
        },
        groups() {
            return this.area?.groups;
        },
        topics() {
            return this.area?.topics;
        },
        tags() {
            return this.area.tags;
        },
        users() {
            return this.area?.users;
        },

        // page -------------------
        page() {
            return this.$store.state.page;
        },
        tasks() {
            return this.page.tasks;
        },
        count1TaskDoneTasks() {
            return this.tasks.items?.filter(el => this.isOneTaskDone(el))?.length;
        },
        count1TaskUndoneTasks() {
            return this.tasks.items?.length - this.count1TaskDoneTasks;
        },
        countContextInactiveTasks() {
            return this.tasks.items?.filter(el => this.isTaskInactive(el))?.length;
        },
        countContextActiveTasks() {
            return this.tasks.items?.length - this.countContextInactiveTasks;
        },

        // context ---
        context() {
            //console.log("REQUESTING context", this.page.context);
            return this.page.context;
        },
        pick() {
            return this.context.pick;
        },
        group() {
            return this.context.group;
        },
        topic() {
            return this.context.topic;
        },
        tag() {
            return this.context.tag;
        },
        usersOfGroup() {
            return this.context.usersOfGroup;
        },
        tab: {
            get() {
                return this.context.tab
            },
            set(v) {
                return this.context.tab = v;
            }
        },
        assignee() {
            return this.context.assignee;
        },

        // msgs -------------------
        msgs() {
            return this.page.msgs;
        },

        // task -------------------
        task() {
            return this.page.task;
        },

        // draggable --------------------
        draggedElement() {
            return this.$store.state.draggable.lastDragElement;
        },

        // routing --------------------
        isRouteHome() {
            return this.$route.name === "home";
        },
        isRouteMy() {
            const thisUserAuth = this.$store.state.session.user?.auth;
            return (this.$route.name === "assignee" && thisUserAuth === this.$route.params.assigneeAuth)
                || (this.isRouteAssigneeTask && thisUserAuth === this.$route.params.assigneeAuth)
                || this.isRouteMyTask
                || this.isRouteMyTaskNew
                || (this.$route.name === "my" && !!thisUserAuth);
        },
        isRouteMyTask() {
            return this.$route.name === "my-task";
        },
        isRouteContext() {
            return this.$route.name === "context" || this.$route.name === "context-done" || this.isRouteContextTask;
        },
        isRouteContextTask() {
            return (this.$route.name === "context-task");
        },
        isRouteRecent() {
            return this.$route.name === "recent";
        },
        isRoutePrio() {
            return this.$route.name === "prio" || this.isRoutePrioTodo || this.isRoutePrioTodoNew;
        },
        isRouteTask() {
            return this.isRouteTopicTask
                || this.isRouteGroupTask
                || this.isRouteContextTask
                || this.isRouteMyTask
                || this.isRoute1Task
                || this.isRouteAssigneeTask
                || this.isRoutePrioTodo
                || this.isRouteSearchTask
                || this.isRouteTaskNew;
        },
        isRouteTaskNew() {
            return this.isRouteTopicTaskNew
                || this.isRouteGroupTaskNew
                || this.isRouteMyTaskNew
                || this.isRouteAssigneeTaskNew
                || this.isRoutePrioTodoNew;
            //|| this.isRouteSearchTaskNew;
        },
        isRouteTopic() {
            return this.$route.name === "topic" || this.$route.name === "topic-done" || this.isRouteTopicTask;
        },
        isRouteTopicTask() {
            return (this.$route.name === "topic-task");
        },
        isRouteTopicTaskNew() {
            return this.$route.name === "topic-task-new";
        },
        isRouteGroup() {
            return this.$route.name === "group" || this.$route.name === "group-done" || this.isRouteGroupTask;
        },
        isRouteGroupTask() {
            return (this.$route.name === "group-task");
        },
        isRouteGroupTaskNew() {
            return !!this.group && this.$route.name === "group-task-new";
        },
        isRouteAssignee() {
            const thisUserAuth = this.$store.state.session.user?.auth;
            return (this.$route.name === "assignee"
                || this.isRouteAssigneeTask) && thisUserAuth !== this.$route.params.assigneeAuth;
        },
        isRouteAssigneeTask() {
            return this.$route.name === "assignee-task" || this.$route.name === "assignee-task-new";
        },
        isRouteAssigneeTaskNew() {
            return this.$route.name === "assignee-task-new";
        },
        isRouteMyTaskNew() {
            return this.$route.name === "my-task-new";
        },
        isRoutePrioTodo() {
            return this.$route.name === "prio-task";
        },
        isRoutePrioTodoNew() {
            return this.$route.name === "prio-task-new";
        },
        isRouteSearchTask() {
            return this.$route.name === "search-task";
        },
        isRouteUsers() {
            return this.$route.name === "users";
        },
        isRouteSearch() {
            return this.$route.name === "search" || this.isRouteSearchTask;
        },
        isRoute1Task() {
            return /*this.$route.name === "home"
                || */
            this.$route.name === "home-task"
            || this.$route.name === "home-done";
        },
        isRoute1TaskDone() {
            return this.$route.name === "home-done";
        },
        isRouteContextDone() {
            return this.isRoute1TaskDone
                || this.$route.name === "context-done"
                || this.$route.name === "group-done"
                || this.$route.name === "topic-done";
        },
        parentListRoute() {
            if (this.isRouteGroupTask) return {
                name: "group",
                params: {groupAuth: this.group.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteTopicTask) return {
                name: "topic",
                params: {topicAuth: this.topic.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteAssigneeTask) return {
                name: "assignee",
                params: {assigneeAuth: this.assignee.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteSearchTask) return {
                name: "search",
                query: {s: this.$route.query?.s, tab: this.$route.query?.tab}
            };
        }
    },
    /*	beforeRouteUpdate(to, from, next){
            console.log("beforeRouteUpdate", to, from);
            //initAuthorization(to);
            next();
        }*/
    methods: {
        do(action, params) {
            return this.$store.dispatch("action", {
                action,
                params
            });
        },
        dispatch(name, params) {
            return this.$store.dispatch(name, params);
        },
        commit(name, payload) {
            return this.$store.commit(name, payload);
        },
        toggleTaskDone(item) {
            if (this.isTaskDone(item)) {
                return this.$store.dispatch("action", {
                    action: "task/update",
                    params: {
                        taskAuth: item.auth,
                        params: {
                            isDone: false,
                            isActive: true,
                            doneAt: null
                        }
                    }
                });
            } else {
                return this.$store.dispatch("action", {
                    action: "task/update",
                    params: {
                        taskAuth: item.auth,
                        params: {
                            isDone: true,
                            isActive: false,
                        }
                    }
                });
            }
        },
        isTaskDone(item) {
            if (!item) return false;
            return !!(item.isDone || item.doneAt);
        },
        isOneTaskDone(item) {
            if (!item) return false;
            if (this.isAnon) return false;
            return this.isTaskInactive(item)
                || !this.isTaskOfMyAttention(item);
        },
        isTaskDelayed(item) {
            if (!item) return false;
            return !!(item.isDelayed || !!item.delayedTill);
        },
        isTaskInactive(item) {
            return this.isTaskDone(item) || this.isTaskDelayed(item);
        },
        isTaskTemplateActive(item) {
            if (!item) return false;
            return !this.isTaskDone(item) && item.repeat;
        },
        isTaskOverdue(item) {
            if (!item) return false;
            if (!item.deadline) return false;
            //const date = iso2Date(item.deadline);
            //date.setDate(date.getDate() + 1);
            const now = new Date();
            return item.deadline < date2Mysql(now);
        },
        isTaskOfMyAttention(item) {
            if (!item) return false;
            return item.userId === this.user.id
                || (!this.isTaskDone(item)
                    && this.isTaskOverdue(item)
                    && (item.ownerId === this.user.id
                        || (!item.ownerId && item.creatorId === this.user.id))
                );
        },
        hasFullAccessToTask(item) {
            if (!item) return false;
            return !!this.groups?.find(el => el.id === item.groupId);
        },
        delayedDateText(item) {
            if (!item) return false;
            let text = null;
            if (item.delayedTill) {
                let hm = iso2HHMM(item.delayedTill);
                if (hm === "00:00") hm = "";
                else hm = " " + hm;
                text = iso2DDMMYYYY(item.delayedTill) + hm;
            } else if (item.isDelayed) text = "Отложена";
            return text;
        },
        nickOf(user) {
            return nick(user);
        },
        showDialogTaskDelay(item) {
            return this.$store.dispatch("task/dialogDelay", {
                item,
            });
        },
        sortTasks() {
            if (this.isRouteRecent) return;
            if (this.isRouteSearch) return;

            const isMyList = this.isRouteMy || this.isRoute1Task;

            const items = this.page.tasks.items;
            //console.log("sortTasks(): ", items.map(el=>el.auth));
            const isSorted = sortItems(items, isMyList ? this.user.id : null/*, this.isSortDesc*/);
            if (!isSorted) {
                //console.log("Sorting hasn't changed");
                return;
            }

            const taskSort = items.map(el => el.auth);
            //this.isSortDesc = !this.isSortDesc;
            //console.log("TASK SORT", taskSort);
            //return;

            //console.log("sortTasks() result: ", items.map(el=>el.auth));
            if (isMyList) {
                return this.do('user/taskSort', {
                    userAuth: this.user.auth,
                    tab: this.context.tab,
                    taskSort,
                });
            } else if (this.group && (this.isRouteGroup || this.isRouteTopic)) {
                return this.do('group/taskSort', {
                    groupAuth: this.group.auth,
                    topicAuth: this.topic?.auth,
                    tab: this.context.tab,
                    taskSort,
                });
            }

        },
        groupOfTask(task) {
            return this.groups?.find(el => el.id === task.groupId);
        },
        topicOfTask(task) {
            return this.topics?.find(el => el.id === task.topicId);
        },
        routeOfTask(group, topic, tag, context, task) {
            //console.log("this.isRouteAssignee: " + this.isRouteAssignee + " : "+this.$route.name);
            //console.log("this.isRouteSearch: " + this.isRouteSearch + " : "+this.$route.name);
            //console.log("this.isRouteTopic: " + this.isRouteTopic + " : "+this.$route.name);
            //console.log("this.isRouteMy: " + this.isRouteMy);
            let route = {name: 'my'};
            let query = {};

            if (tag) route = {
                name: 'topic-task',
                params: {topicAuth: tag.id, taskAuth: task.auth},
            };
            else if (context) route = {
                name: 'context-task',
                params: {context: context.id, taskAuth: task.auth},
            };
            else if (topic) route = {
                name: 'topic-task',
                params: {topicAuth: topic.auth, taskAuth: task.auth},
            };
            else if (group) route = {
                name: 'group-task',
                params: {groupAuth: group.auth, taskAuth: task.auth}
            };

            if (task.isDelayed) query = {tab: TABS.DELAYED};
            else if (task.delayedTill) query = {tab: TABS.DELAYED};
            else if (task.isTemplate) query = {tab: TABS.TEMPLATES};

            if (this.$route.query || query) route.query = {...this.$route.query, ...query};
            if (route.params && typeof route.params?.skipContext === "undefined") route.params.skipContext = true;

            return route;
        },
        routeOfContext(context) {
            let query = undefined;
            const rootId = rootContextId(context);
            let rootPick;
            if (rootId) rootPick = this.picks.find(el => el.contextId === rootId);
            if (context.isPick || rootPick) {
                const c = rootPick || context;
                if (contextHasDelayedTill(c)) query = {tab: TABS.DELAYED};
                //console.log("PICK", c.conds, query);
            }
            return {name: "context", params: {contextId: context.contextId}, query};
        },
        routeOfContextTask(context, task) {
            return {name: "context-task", params: {context: context.contextId, taskAuth: task.auth}};
        },
        /**
         * @deprecated
         * @param item
         * @returns {{name: string, params: {groupAuth}}}
         */
        routeOfGroup(item) {
            return {name: "group", params: {groupAuth: item.auth}};
        },
        /**
         * @deprecated
         * @param item
         * @returns {{name: string, params: {topicAuth}}}
         */
        routeOfTopic(item) {
            return {name: "topic", params: {topicAuth: item.auth}};
        },
        /**
         * @deprecated
         * @param item
         * @returns {{name: string, params: {topicAuth}}}
         */
        routeOfTag(item) {
            //return {name: "topic", params: {topicAuth: item.topicAuth}, query: {tag: item.name}};
            return {name: "topic", params: {topicAuth: item.id}};
        },
        colorByCss(css) {
            if (!css) return null;
            return Config.COLORS.find(el => el.css === css);
        }
    }
}