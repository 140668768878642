import Vue from "vue";
import Config from "@/config";
import {sortByAuth} from "@/utils/group";
import {date2Iso} from "@/utils/date";
import uiMutations from "@/store/ui/mutations";
import {TABS} from "@/utils/task";
import router from "@/router";
import {initPicksInArea} from "@/utils/area";

const mutations = {
    contextCreate: (state, payload) => {
        console.log("MUTATION context/create", payload);

        // after creation - call this
        payload.mutation = "contextAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: payload.contextId,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    contextPick: (state, payload) => {
        console.log("MUTATION context/pick", payload);

        // after creation - call this
        payload.mutation = "contextAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: payload.contextId,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    contextAppend: (state, payload) => {
        console.log("MUTATION context/append", payload);

        const item = payload?.contextAppend?.item;
        if (!item) return;

        const area = state.session.area;

        const contexts = payload?.append?.picks;

        let items = area.picks;

        // insert item
        if (items?.findIndex(el => el.id === item.id) === -1) {
            items.push(item);
            console.log("APPENDED", item, items);
        }
    },
    contextUpdate: (state, payload) => {
        console.log("MUTATION context/update", payload);

        if (!payload?.contextId) return;
        if (!payload.params) return;

        const update = (item) => {
            Object.keys(payload.params).forEach(key => {
                Vue.set(item, key, payload.params[key]);
            });
        }

        // update item in session
        const contextId = payload.contextId;
        const items = [
            ...state.session.area.picks,
            ...state.session.area.groups,
            ...state.session.area.topics,
            ...state.session.area.tags,
        ];
        let item;
        if (items?.length) {
            item = items.find(el => el.contextId === contextId);
            if (item) {
                update(item);
                initPicksInArea(state.session.area);
            }
        }

        // update item in context
        if (state.page.context.item && state.page.context.item.contextId === contextId) update(state.page.context.item);
    },
    contextDelete: (state, payload) => {
        console.log("MUTATION context/delete", payload);

        // check we have this
        const contextId = payload.contextId;
        const index = state.session.area.picks.findIndex(el => el.id === contextId);
        if (index === -1) return;
        const context = state.session.area.picks[index];

        // delete from area
        state.session.area.picks.splice(index, 1);
        console.log("DELETED "+index+": "+contextId);

        // clear context
        if (state.page.context.item?.id === context.id) {
            state.page.context.item = null;
            state.page.tasks.items = null;
        }

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: context.id,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);

        if (router.currentRoute.params?.contextId === context.id) {
            router.push({name: "home"});
        }
    },

    /* --- TODO REVIEW --- */
    contextFav: (state, payload) => {
        console.log("MUTATION context/fav", payload);
        if (!payload?.params) return;

        // remove archivedAt if contextgoes to favs
        const params = payload.params;
        if (params.isFav) {
            params.isArchived = false;
            params.archivedAt = null;
        }

        mutations.contextUpdate(state, payload);
    },
    contextArchive: (state, payload) => {
        console.log("MUTATION context/archive", payload);
        if (!payload?.params) return;

        const params = payload.params;
        if (params.isArchived) {
            params.archivedAt = date2Iso(new Date());
            params._isArchived = true;
            //params.isFav = false;
        } else {
            params.archivedAt = null;
            params._isArchived = false;
        }

        mutations.contextUpdate(state, payload);
    },
    contextTaskSort: (state, payload) => {
        console.log("MUTATION context/taskSort", payload);

        const contextAuth = payload.contextAuth;
        const topicAuth = payload.topicAuth;
        if (!contextAuth && !topicAuth) return;

        const newTaskSort = payload.taskSort;
        if (!newTaskSort?.length) return;

        // check the requested context is ours
        let context;
        if (topicAuth && topicAuth === state.page.context.topic?.auth) context = state.page.context.topic;
        else if (!topicAuth && contextAuth && contextAuth === state.page.context.context?.auth) context = state.page.context.context;
        else return;

        //console.log("CONTEXT topic", state.page.context.topic);
        //console.log("CONTEXT context", state.page.context.context);

        // apply to tasks
        const action = () => {
            const tasks = state.page.tasks.items;
            sortByAuth(tasks, newTaskSort);
        }

        // find which task is moved
        /*const sortedTab = state.page.context.tab;
        let currentTaskSort = context.taskSort;
        if ( typeof currentTaskSort === "string" ) {
            try { currentTaskSort = JSON.parse(currentTaskSort);}
            catch (e) {return;}
        }
        currentTaskSort = Array.isArray(currentTaskSort) ? currentTaskSort : currentTaskSort[sortedTab];
        const movedItem = newTaskSort.find((el, i)=>el !== currentTaskSort[i]);
        console.log("Moved item", movedItem);*/
        //uiMutations.uiSetActorOfcontextTaskSort(state, {userAuth: payload.userAuth});
        if (payload.initiatorAuth && payload.taskAuth) {
            uiMutations.uiSetActorOfTask(state, {
                userAuth: payload.userAuth,
                subjectAuth: payload.taskAuth,
                icon: "mdi-hand-back-right-outline",
                timeout: 900,
            });
            setTimeout(action, 300);
        } else action();

        // todo apply to context.taskSort
        //Vue.set(context, "taskSort", context.taskSort);
        //console.log("context.taskSort", context.taskSort);

        /*const sourceIndex = payload.sourceIndex;
        const destinationIndex = payload.destinationIndex;
        const items = state.page.tasks.items;

        if (!items) return;
        if (sourceIndex < 0 || sourceIndex >= items.length) return;
        if (destinationIndex < 0 || destinationIndex >= items.length) return;

        if (sourceIndex !== destinationIndex) {
            const source = items[sourceIndex];
            items.splice(sourceIndex, 1);
            items.splice(destinationIndex, 0, source);
        }*/

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: contextAuth,
        }];
        if (topicAuth) broadcast.push({
            entity: "topic",
            auth: topicAuth,
        });
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    contextSort: (state, payload) => {
        console.log("MUTATION context/sort", payload);
        const user = state.session.user;
        if (!user) return;

        const contexts = state.session.area.contexts;
        if (!contexts) return;

        sortByAuth(contexts, payload.contextSort);
        //console.log("AFTER sort", context.children.map(c=>c.auth));

        user.contextSort = payload.contextSort;
    },
    contextAssign: (state, payload) => {
        console.log("MUTATION context/assign", payload);
        if (!payload?.contextAuth) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const auth = payload.contextAuth;
        const userAuth = payload.assigneeAuth;

        let userId = null;
        if (userAuth) {
            const user = state.session.area.users.find(el => el.auth === userAuth);
            //console.log("USER FOUND", user);
            if (user) userId = user.id;
            else return;
        }

        let property = "userId";

        // update item in a area
        const items = state.session.area.contexts;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) item[property] = userId;
        }

        // update item in context
        if (state.page.context.context?.auth === auth) state.page.context.context[property] = userId;

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "context",
            auth: payload?.contextAuth,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
};

export default mutations;
