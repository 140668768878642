import Vue from 'vue';
import Config from '@/config';
import {sortByAuth} from "@/utils/group";
import {date2Iso} from "@/utils/date";
import {tagsFromText} from "@/utils/task";
import {initPicksInArea} from "@/utils/area";

const mutations = {
    /*topicItem: (state, payload) => {
        console.log("MUTATION topic/item", payload);
        if (!payload?.tasks?.items?.length) return;


    },*/
    topicUpdate: (state, payload) => {
        console.log("MUTATION topic/update", payload);

        if (!payload?.topicAuth) return;
        if (!payload?.params) return;

        const update = (item) => {
            Object.keys(payload.params).forEach(key => {
                Vue.set(item, key, payload.params[key]);
                //console.log("Topic."+key+": "+payload.params[key]);
            });
        }

        // update item in session
        const auth = payload.topicAuth;
        const items = state.session.area.topics;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) update(item);
        }

        // update item in context
        if (state.page.context.topic && state.page.context.topic.auth === auth) update(state.page.context.topic);
    },
    topicAssign: (state, payload) => {
        console.log("MUTATION topic/assign", payload);
        if (!payload?.topicAuth) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const auth = payload.topicAuth;
        const userAuth = payload.assigneeAuth || payload.ownerAuth;

        let userId = null;
        if (userAuth) {
            const user = state.session.area.users.find(el => el.auth === userAuth);
            //console.log("USER FOUND", user);
            if (user) userId = user.id;
            else return;
        }

        let property;
        if (typeof payload.ownerAuth !== "undefined") property = "ownerId";
        else property = "userId";

        // update item in a area
        const items = state.session.area.topics;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) item[property] = userId;
        }

        // update item in context
        if (state.page.context.topic?.auth === auth) state.page.context.topic[property] = userId;

        // broadcast to subscribers of this
        if (item) {
            const broadcast = [{
                entity: "group",
                auth: item.groupAuth,
            }];
            payload.broadcast = broadcast;
            console.log("broadcast", broadcast);
        }
    },
    topicAppend: (state, payload) => {
        console.log("MUTATION topic/append", payload);
        if (!payload?.topicAppend?.topic) return;
        if (!payload?.topicAppend?.group) return;

        const topic = payload.topicAppend.topic;
        const group = payload.topicAppend.group;

        const topicExists = state.session.area.topics.find(el => el.auth === topic.auth);
        //console.log("topicExists", topicExists);
        if (topicExists) return;

        const groupExists = state.session.area.groups.find(el => el.auth === group.auth);
        //console.log("groupExists", groupExists);
        if (!group) return;

        // append topic to group
        topic.isTopic = true;
        state.session.area.topics.unshift(topic);
        const groupTopics = state.session.area.topics?.filter(topic => topic.groupId === group.id);
        try {
            if (typeof group.topicSort === "string") groupExists.topicSort = JSON.parse(group.topicSort);
            else groupExists.topicSort = group.topicSort;
        } catch (e) {
        }
        //console.log("groupExists.topicSort", groupExists.topicSort);
        sortByAuth(groupTopics, groupExists.topicSort);
        groupExists.children = groupTopics;
    },
    topicDelete: (state, payload) => {
        console.log("MUTATION topic/delete", payload);

        // delete from area
        const auth = payload.topicAuth;
        const topic = state.session.area.topics?.find(el => el.auth === auth);
        if (topic) {
            const index = state.session.area.topics.findIndex(el => el.auth === auth);
            if (index >= 0) state.session.area.topics.splice(index, 1);

            // delete from parent group children
            const group = state.session.area.groups?.find(el => el.auth === topic.groupAuth);
            if (group?.children) {
                const index = group.children.findIndex(el => el.auth === auth);
                if (index >= 0) group.children.splice(index, 1);
            }
        }

        // clear context
        if (state.page.context.topic?.auth === auth) {
            state.page.context.topic = null;
            state.page.tasks.items = null;
        }

        // broadcast to subscribers of this
        if (topic) {
            const broadcast = [{
                entity: "group",
                auth: topic.groupAuth,
            }];
            payload.broadcast = broadcast;
            console.log("broadcast", broadcast);
        }
    },
    topicArchive: (state, payload) => {
        console.log("MUTATION topic/archive", payload);
        if (!payload?.params) return;

        const params = payload.params;
        if (params.isArchived) {
            params.archivedAt = date2Iso(new Date());
            params._isArchived = true;
            //params.isFav = false;
        } else {
            params.archivedAt = null;
            params._isArchived = false;
        }

        mutations.topicUpdate(state, payload);
    },
    topicTagSort: (state, payload) => {
        console.log("MUTATION topic/tagSort", payload);

        const topic = state.session.area.topics.find(el => el.auth === payload.topicAuth);
        if (!topic) return;

        sortByAuth(topic.children, payload.tagSort);
        //console.log("AFTER sort", group.children.map(c=>c.auth));

        Vue.set(topic, "tagSort", payload.tagSort);
        console.log("payload.tagSort", payload.tagSort);

        initPicksInArea(state.session.area);

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "topic",
            auth: topic.auth,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
};

export default mutations;
