import {TABS} from "@/utils/task";
import {TAB_MSGS} from "@/utils/msg";

export default {
    isInited: false,
    is403: false,
    is404: false,

    /**
     * Current selected context objects.
     */
    context: {
        item: null, // current item
        pick: null,
        group: null,
        topic: null,
        tag: null,
        tab: TABS.ACTUAL,

        assignee: null,
        prio: false,
        //search: null,
        usersOfGroup: null,
        isFullAccess: true,
        isRecent: false,

        TITLE: null,
    },

    /**
     * Actual task list from current context.
     */
    tasks: {
        isInited: false,
        items: null,
        soonItems: null,
        tags: null,
        //msgs: null,
        //files: null,
    },

    /**
     * Actual task (edited one).
     */
    task: {
        isInited: false,
        isSealed: false,
        isFullAccess: false,

        item: null,
        children: null,
        parents: null,
        files: null,
        template: null,
        assignee: null,
        group: null,
        topic: null,

        TITLE: null,
        //siblings: null,
        //totalActualItems: null,
    },

    deletedTaskAuths: [],

    msgs: {
        items: null,
        tabId: TAB_MSGS,
    },

    msg: {
        item: {
            text: null,
            action: null,
        },
        uploads: null,
    },

    // --- dynamic data from BE --- todo review naming

    uploadResult: {
        files: null,
    },
    appendTopic: {
        group: null,
        topic: null,
    },
    taskUpdate: {
        item: null,
    },
    contextAppend: {
        item: null,
    },
}