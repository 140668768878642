import Config from '@/config';
import {TABS} from "@/utils/task";
import Vue from "vue";
import {end} from "@/utils/string";

const actions = {
    /**
     * Fetches list of messages for specified task.
     * @param store
     * @param params
     * @returns {*}
     */
    /*msgs(store, params) {
        return store.dispatch('get', {
            action: 'task/msgs',
            params
        }, {root: true});
    },*/
    /**
     * @deprecated todo should be used via "action"
     * @param store
     * @param params
     * @returns {*}
     */
    update(store, params) {
        console.log("ACTION task/update", params);

        // local change
        store.commit("taskUpdate", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'task/update',
            params
        }, {root: true});
    },
    /*assign(store, params) {
        console.log("ACTION task/assign", params);

        // local change
        store.commit("taskAssign", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'task/assign',
            params
        }, {root: true});
    },
    /!**
     * @deprecated not used, moved to action
     * @param store
     * @param params
     * @returns {*}
     *!/
    delete(store, params) {
        console.log("ACTION task/delete", params);

        // local change
        store.commit("taskDelete", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'task/delete',
            params
        }, {root: true});
    },*/
    menu(store, params) {
        return store.commit('uiMenu', {
            menu: store.rootState.ui.taskMenu,
            params
        }, {root: true});
    },
    prioMenu(store, params) {
        return store.commit('uiMenu', {
            menu: store.rootState.ui.taskPrioMenu,
            params
        }, {root: true});
    },
    dialogDelay(store, params) {
        return new Promise((resolve, reject) => {
            //console.log("dialogDelay", params);
            store.rootState.ui.dialogTaskDelay.item = params.item;
            store.rootState.ui.dialogTaskDelay.callback = (res) => {
                Object.keys(res).forEach(key => {
                    Vue.set(params.item, key, res[key]);
                })
                if (params?.item?.auth) {
                    store.dispatch("task/update", {
                        taskAuth: params.item.auth,
                        params: res
                    }, {root: true});
                }
                return resolve(res);
            };
            store.rootState.ui.dialogTaskDelay.isVisible = true;
        });
    },
    dialogMove(store, params) {
        return new Promise((resolve, reject) => {
            store.rootState.ui.dialogTaskMove.item = params.item;
            store.rootState.ui.dialogTaskMove.callback = (res) => {
                //console.log("res", res);

                if (params?.item?.auth) {
                    store.dispatch("task/group", {
                        //taskAuth: params.item.auth,
                        ...res,
                    }, {root: true});
                }
                return resolve(res);
            };
            store.rootState.ui.dialogTaskMove.isVisible = true;
        });
    },
    dialogDeadline(store, params) {
        if (!params?.item?.auth) return;

        // for templates we only allow to select a day interval
        if (params.item.isTemplate) return new Promise((resolve, reject) => {
            console.log("dialogCalendar", params);
            store.rootState.ui.dialogSelect.item = params.item;
            store.rootState.ui.dialogSelect.prop = "deadlineDays";
            store.rootState.ui.dialogSelect.title = "Укажите дедлайн";
            store.rootState.ui.dialogSelect.label = "Выбрать другой срок";
            store.rootState.ui.dialogSelect.text = "Дедлайн будет установлен от даты активации шаблона.";
            store.rootState.ui.dialogSelect.popularOptions = [
                {value:1, text:"1 день"},
                {value:2, text:"2 дня"},
                {value:3, text:"3 дня"},
                {value:4, text:"4 дня"},
                {value:5, text:"5 дней"},
                {value:6, text:"6 дней"},
                {value:7, text:"1 неделя", width: "47.5%"},
                {value:14, text:"2 недели", width: "47.5%"},
                //{value:30, text:"1 месяц"},
            ];
            store.rootState.ui.dialogSelect.options = [...new Array(90)].map((el,index)=>({
                text: (index+1)+" "+end(index+1, "день", "дня", "дней"),
                value: index+1,
            }));
            store.rootState.ui.dialogSelect.callback = (res) => {
                console.log("Selected days", res.days);
                store.dispatch("task/update", {
                    taskAuth: params.item.auth,
                    params: res
                }, {root: true});
                return resolve(res);
            };
            store.rootState.ui.dialogSelect.isVisible = true;
        });
        // for regular tasks - show calendar
        else return new Promise((resolve, reject) => {
            console.log("dialogCalendar", params);
            const minDate = new Date();
            minDate.setDate(minDate.getDate()-14);
            store.rootState.ui.dialogCalendar.item = params.item;
            store.rootState.ui.dialogCalendar.title = "Укажите дедлайн";
            store.rootState.ui.dialogCalendar.prop = "deadline";
            store.rootState.ui.dialogCalendar.minDate = minDate;
            store.rootState.ui.dialogCalendar.callback = (res) => {
                store.dispatch("task/update", {
                    taskAuth: params.item.auth,
                    params: res
                }, {root: true});
                return resolve(res);
            };
            store.rootState.ui.dialogCalendar.isVisible = true;
        });
    },
    dialogTemplate(store, params) {
        if (!params?.item?.auth) return;
        return new Promise((resolve, reject) => {
            //console.log("dialogTemplate", params);
            store.rootState.ui.dialogTaskTemplate.item = params.item;
            store.rootState.ui.dialogTaskTemplate.callback = (res) => {
                store.dispatch("action", {
                    action: "task/template",
                    params: {
                        taskAuth: params.item.auth,
                        params: res
                    }
                }, {root: true});

                //console.log("TEMPLATE SAVED", resSaved);
                // switch to templates tab
                //store.rootState.page.context.tab = TABS.TEMPLATES;
                store.commit("uiSetTab", TABS.TEMPLATES, {root: true});

                return resolve(res);
            };
            store.rootState.ui.dialogTaskTemplate.isVisible = true;
        });
    },
    group(store, params) {
        console.log("ACTION task/group", params);

        // local change
        store.commit("taskGroup", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'task/group',
            params,
        }, {root: true});
    },

    // TODO REVIEW ---------------

    refetch(store, params) {
        return store.dispatch('get', {
            action: 'task-item', params: {
                auth: store.rootState.page?.todo?.item?.auth,
            }
        }, {root: true})
            .then(result => {
                store.commit('setPage', result, {root: true});
                return result;
            })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            });
    },
    refetchWithGroup(store, params) {
        return store.dispatch('get', {
            action: 'group-item,task-item', params: {
                todoAuth: store.rootState.page?.todo?.item?.auth,

                auth: store.rootState.page?.group?.group?.auth,
                topicAuth: store.rootState.page?.group?.topic?.auth,
                tab: store.rootState.page?.group?.tab,
                assigneeAuth: store.rootState.page?.group?.assignee?.auth,
                prio: store.rootState.page?.group?.prio,
                search: store.rootState.ui.search.value,
            }
        }, {root: true})
            .then(result => {
                store.commit('setPage', result, {root: true});
                return result;
            })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            });
    },

    create(store, params) {
        return store.dispatch('post', {
            action: 'task-create',
            params
        }, {root: true})
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            }).finally(() => {
                store.state.isSending = false;
            });
    },
    sort(store, params) {

        return store.dispatch('post', {
            action: 'group-task-sort',
            params
        }, {root: true}).then((res) => {
            return res;
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
        });
    },
};

export default actions;
