import Vue from "vue";
import {sortByAuth} from "@/utils/group";

export const initPicksInArea = (area) => {
    if (!area.picks) Vue.set(area, "picks", []);
    area.picks?.forEach(pick => {
        if (pick.groupIds?.length) {
            // append child groups
            const groups = area.groups?.filter(group => pick.groupIds.includes(group.id)).map(el => ({
                ...el,
                contextId: pick.contextId + el.contextId,
                children: null
            }));

            let topics, tags;
            if (pick.topicIds?.length) topics = area.topics?.filter(topic => pick.topicIds.includes(topic.id));
            if (pick.tagIds?.length) tags = area.tags?.filter(tag => pick.tagIds.includes(tag.id));

            if (pick.topicIds?.length) {
                groups.forEach(group => {
                    Vue.set(group, "parent", pick);

                    const groupTopics = topics.filter(topic => topic.groupId === group.id).map(el => ({
                        ...el,
                        contextId: pick.contextId + el.contextId,
                        children: null
                    }));
                    sortByAuth(groupTopics, group.topicSort);
                    Vue.set(group, "children", groupTopics);
                    groupTopics?.forEach(el => Vue.set(el, "parent", group));

                    if (pick.tagIds?.length) {
                        groupTopics.forEach(topic => {
                            const topicTags = tags.filter(tag => tag.topicId === topic.id).map(el => ({
                                ...el,
                                contextId: pick.contextId + el.contextId,
                            }));
                            sortByAuth(topicTags, topic.tagSort);
                            Vue.set(topic, "children", topicTags);
                            //console.log("topic tags 1", group.name+"/"+topic.name, topicTags);
                            topicTags?.forEach(el => Vue.set(el, "parent", topic));
                        });
                    }
                });
            }

            // collapse single group
            if (groups.length === 1) {
                Vue.set(pick, "children", groups[0].children);
                groups[0].children?.forEach(el => Vue.set(el, "parent", pick));
            } else Vue.set(pick, "children", groups);
        }
        /*if ( pick.conds?.length === 1 && pick.conds[0].length === 1 && pick.conds[0][0].key === "tagId" ) {
            //Vue.set(list, "isTag", true);
            pick.name = "#" + pick.name;
            return;
        }
        else if ( pick.conds?.length === 1 && pick.conds[0].length === 1 && pick.conds[0][0].key === "topicId" ) {
            //Vue.set(list, "isTag", true);
            //list.name = "#" + list.name;
            return;
        }

        let groupContexts = area.contexts?.filter(context => !!context.groupId && context.parentId === pick.id);
        //console.log("contexts", area.contexts);
        //console.log("groupContexts", groupContexts);
        if (groupContexts?.length === 1) {

            let groupContext = groupContexts[0];
            const group = area.groups.find(el => el.id === groupContext.groupId);
            //groupContext.name = group.name;

            const topicContexts = group.children.map(topic => {
                const topicContext = area.contexts.find(el => el.topicId === topic.id);
                if (!topicContext) return null;
                Vue.set(topicContext, "isTopic", true);
                //topicContext.name = topic.name;
                return topicContext;
            }).filter(el => !!el);
            //Vue.set(list, "isGroup", true);
            //Vue.set(groupContext, "children", topicContexts);
            //topicContexts?.forEach(el => Vue.set(el, "parent", groupContext));
            if (topicContexts?.length > 1) {
                Vue.set(pick, "children", topicContexts);
                topicContexts?.forEach(el => Vue.set(el, "parent", pick));
            }
        } else {
            groupContexts?.forEach(groupContext => {
                const group = area.groups.find(el => el.id === groupContext.groupId);
                //groupContext.name = group.name;

                const topicContexts = group.children.map(topic => {
                    const topicContext = area.contexts.find(el => el.topicId === topic.id);
                    if (!topicContext) return null;
                    Vue.set(topicContext, "isTopic", true);
                    //topicContext.name = topic.name;
                    return topicContext;
                }).filter(el => !!el);
                Vue.set(groupContext, "isGroup", true);
                Vue.set(groupContext, "children", topicContexts);
                topicContexts?.forEach(el => Vue.set(el, "parent", groupContext));
            });
            Vue.set(pick, "children", groupContexts);
            groupContexts?.forEach(el => Vue.set(el, "parent", pick));
        }*/
    });

    console.log("AREA PICKS INITED", area.picks);
};
