import Vue from 'vue';
import headful from "headful";
import router from '../router/index';
import storage from "@/utils/storage";
import groupMutations from "./group/mutations";
import topicMutations from "./topic/mutations";
import taskMutations from "./task/mutations";
import msgMutations from "./msg/mutations";
import contextMutations from "./context/mutations";
import pickMutations from "./pick/mutations";
import uiMutations from "./ui/mutations";
import wsMutations from "./ws/mutations";
import userMutations from "./user/mutations";
import fileMutations from "./file/mutations";
import Config from "@/config";
import {nanoid} from "nanoid";

const mutations = {

    // we use root mutations as moduled ones do not have access to root state...
    // see https://github.com/vuejs/vuex/issues/344
    ...groupMutations,
    ...topicMutations,
    ...taskMutations,
    ...pickMutations,
    ...msgMutations,
    ...contextMutations,
    ...uiMutations,
    ...userMutations,
    ...fileMutations,
    ...wsMutations,

    setResponse: async (state, payload) => {
        console.log("MUTATION setResponse:", payload);
        if (!state.response) state.response = {};
        if (!payload) state.response = {};

        // set response
        if (payload.id) {
            // incoming response from server for some of our requests
            let index = state.wsRequests.findIndex(el => el.id === payload.id);
            if (index !== -1) {
                let request = state.wsRequests[index];
                if (request) request.response = payload;

                // delete request from request queue
                state.wsRequests.splice(index, 1);
                //console.log("DELETED request: "+index+" of "+state.wsRequests.length);
            }
        }

        // check there is error
        if (payload.error) {
            mutations.uiError(state, payload.error);
            return;
        }

        // set state vars
        if (payload.result?.state) {
            mutations.setState(state, payload.result.key, payload.result.state);
            //mutations.setPage(state, payload.result.page[actionName]);
            /*for (const actionName in payload.result.page) {
                //console.log("MUTATION setResponse: "+actionName);
                mutations.setPage(state, payload.result.page[actionName]);
            }*/

            //mutations.setSession(state, payload.result.session);
            //userItem(state, payload.result.session.user);
        }

        // call custom mutations
        if (payload.result?.mutation) {
            console.log("CHILD MUTATION: " + payload.result?.mutation);
            if (typeof mutations[payload.result.mutation] === "function") {
                await mutations[payload.result.mutation](state, payload.result.state);
            } else {
                // it is ok to have no mutation for requested action
                //console.warn("Unknown mutation: " + payload.result.mutation);
            }
        }

        // resolve response promise
        if (payload.id) {
            // incoming response from server for some of our requests
            let requestIndex = state.wsRequests.findIndex(el => el.options?.id === payload.id);
            if (requestIndex >= 0) {
                const request = state.wsRequests[requestIndex];
                request.response = payload;
                request.resolve();
                state.wsRequests.splice(requestIndex, 1);
            }
        }
    },
    setState: (state, stateKey, payload) => {
        console.log("MUTATION setState:", stateKey, payload);
        for (const key in payload) {
            if (key === "mutation") continue;
            if (key.match(/^hash_.+/)) continue;

            const value = payload[key];
            const valueHash = payload["hash_" + key];
            //console.log(key);
            //console.log("\tnew hash: " + valueHash);
            if (valueHash) {
                const prevHash = state[stateKey]["hash_" + key];
                //console.log("\tprev hash: " + prevHash);
                if (valueHash === prevHash) {
                    //console.log("\tSKIPPED: "+key);
                    continue;
                }
            }
            //console.log("Setting "+stateKey+"."+key, value);
            state[stateKey][key] = value;
            if (typeof state[stateKey][key] === "object") {
                const item = state[stateKey][key];
                Vue.set(item, "isInited", true);
            }
            //state[stateKey]["hash_" + key] = valueHash;
        }

        state[stateKey].isInited = true;
        //console.log("Inited: "+stateKey);

        /*else {
            for (const key in payload) {
                const data = payload[key];
                if (data) {
                    //console.log("TDK", payload.page[actionName].TITLE);
                    /!*const {TITLE, KEYWORDS, DESCRIPTION} = payload.page[actionName];
                    [
                        ["title", TITLE],
                        ["keywords", KEYWORDS],
                        ["description", DESCRIPTION],
                        ["url", Config.LIVE_HOST + self.location.pathname],
                    ].forEach(([key, value]) => {
                        if (value) {
                            headful({
                                [key]: value,
                            });
                        }
                    });*!/
                }
            }
        }*/

        state.ui.isInited = true;
    },
    setCommon: (state, payload) => {
        for (const key in payload.common) {
            // внимание, такой подход не реактивится:
            //state.page[key] = payload.page[key];
            // поэтому явным образом вызываем Vue.set():
            Vue.set(state.common, key, payload.common[key]);
        }
    },
    setSession: (state, payload) => {
        //console.log("MUTATION setSession:", payload);
        if (payload) {
            if (!state.session) state.session = {};

            //Vue.set(state.session, "user", payload.session.user);
            for (const key in payload) {
                Vue.set(state.session, key, payload[key]);
            }

            // todo move to userMutations?
            const user = state.session.user;
            if (typeof user.groupSort === "string") user.groupSort = JSON.parse(user.groupSort);

            if (payload.workspace?.auth) {
                storage.set(Config.STORAGE_WORKSPACE_AUTH, payload.workspace?.auth);
                //console.log("Workspace set: " + payload.session.workspace?.auth);
            } else {
                storage.clear(Config.STORAGE_WORKSPACE_AUTH);
                console.log("Workspace unset");
            }
        }
    },

    updateWindowWidth: (state) => {
        state.windowWidth = window.innerWidth;
    },
    updateWindowHeight: (state) => {
        state.windowHeight = window.innerHeight;
    },

    soon: (state) => {
        state.withSoonDialog = true;
    },
};

export default mutations;
